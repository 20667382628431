import React, {
  useCallback,
  useState,
  useMemo
} from 'react'
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@mui/material';
import InquiredProperty from '../components/inquired-property/inquired-property';
import ConfirmModal from '../components/confirm-modal/confirm-modal';
import { rentalAgreement } from '../data/rental-agreement';
import API_BASE_URL from '../config';
import {
  getSelectedDateFromSelectedWeek,
  getFutureDateFromFutureWeek
} from '../utils/date-utils';
import { format } from 'date-fns';
import RentalAddons from '../components/rental-addons/rental-addons';

import './booking-form.css';

const fetchAvailableProperty = async (propertyId, startWeek, endWeek, year, minBeds) => {
  const response = await fetch(`${API_BASE_URL}/api/properties/${propertyId}/available?startWeek=${startWeek}&endWeek=${endWeek}&year=${year}&minBeds=${minBeds}`, {
    credentials: 'include',
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY
    }
  });
  return response.json();
}

const fetchAvailableAddons = async (propertyId) => {
  const response = await fetch(`${API_BASE_URL}/api/addons/property/${propertyId}`, {
    credentials: 'include',
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY
    }
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
}

const defaultFormData = {
  firstName: '',
  lastName: '',
  address: '',
  email: '',
  phone: '',
  postalCode: '',
  city: '',
  country: '',
  extraInfo: '',
  message: '',
  rentalAgreementConfirmed: false
}

const BookingForm = () => {
  const location = useLocation();
  const { propertyId } = useParams();
  const params = new URLSearchParams(location.search);
  const startWeek = params.get('startWeek');
  const endWeek = params.get('endWeek');
  const year = params.get('year');
  const minBeds = params.get('minBeds');
  const [selectedAddons, setSelectedAddons] = useState({});

  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [formData, setFormData] = useState(defaultFormData);

  const validate = useCallback(() => {
    let newErrors = {};

    // Name validation
    if (!formData.firstName.trim()) {
      newErrors.firstName = 'Ange ett förnamn namn';
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Ange ett efternamn';
    }

    // E-mail validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Ange en giltig e-postadress (ex namn@domain.se)';
    }

    // (Phone) Number validation
    const phoneRegex = /^\d{9,11}$/;
    const cleanedNumber = formData.phone.replace(/\s+/g, '');
    if (!phoneRegex.test(cleanedNumber)) {
      newErrors.phone = 'Ange ett giltigt telefonnummer (ex 0701234567)';
    }

    if (!formData.rentalAgreementConfirmed) {
      newErrors.rentalAgreementConfirmed = 'Du måste läsa och godkänna hyresvillkoren';
    }

    setErrors(newErrors);
    const isValid = Object.keys(newErrors).length === 0;
    return isValid;
  }, [formData.email, formData.firstName, formData.lastName, formData.phone, formData.rentalAgreementConfirmed]);

  const [errors, setErrors] = useState({});

  const {
    data: propertyData,
    error,
    isLoading
  } = useQuery(
    ['availableProperty', propertyId, startWeek, endWeek, year, minBeds],
    () => fetchAvailableProperty(propertyId, startWeek, endWeek, year, minBeds),
    {
      enabled: true,
    }
  );

  const {
    data: addonsData,
    error: addonsError,
    isLoading: isAddonLoading
  } = useQuery(
    ['availableAddons', propertyId],
    () => fetchAvailableAddons(propertyId),
    {
      enabled: Boolean(propertyId)
    }
  );

  const handleAddonCheckboxChange = (addonId) => {
    setSelectedAddons(prevState => ({
      ...prevState,
      [addonId]: !prevState[addonId]
    }));
  }

  const handleAddonDropDownChange = (addonId, value) => {
    setSelectedAddons(prevState => ({
      ...prevState,
      [addonId]: value
    }));
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const selectedAddonsSummary = useMemo(() => {
    let totalCost = 0;

    if (!addonsData || addonsData.length === 0 || !selectedAddons) {
      return {
        selectedAddons: [],
        totalCost: 0
      };
    }

    // Array to hold the summarized addons
    const selectedSummary = addonsData
      .filter(addon => selectedAddons[addon.addon_id]) // Check if the addon is selected
      .map(addon => {
        const quantity = selectedAddons[addon.addon_id] === true ? 1 : parseInt(selectedAddons[addon.addon_id], 10);
        const cost = addon.price_per_unit * quantity;
        totalCost += cost;

        return {
          addon_id: addon.addon_id,
          name: addon.name,
          quantity,
          unit_price: addon.price_per_unit,
          cost
        };
      });

    // Return the summarized object
    return {
      selectedAddons: selectedSummary,
      totalCost
    };
  }, [addonsData, selectedAddons]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    if (validate()) {
      // Hantera inskick av data här, t.ex. genom att skicka den till en server eller spara i state.
      const payload = {
        country: formData.country,
        email: formData.email,
        endDate: format(getFutureDateFromFutureWeek(year, startWeek, endWeek), 'yyyy-MM-dd'),
        endWeek,
        endYear: year,
        extraAddons: JSON.stringify(selectedAddonsSummary, null, null),
        extraInfo: formData.extraInfo,
        firstName: formData.firstName,
        lastName: formData.lastName,
        message: formData.message,
        phone: formData.phone,
        postalAddress: formData.city,
        postalNumber: formData.postalCode,
        price: propertyData.total_price,
        rentalPropertyId: propertyId,
        rentalPropertyName: propertyData.name,
        startDate: format(getSelectedDateFromSelectedWeek(year, startWeek), 'yyyy-MM-dd'),
        startWeek,
        startYear: year,
        streetAdress: formData.address,
        subject: 'Bokningsförfrågan',
        totalPrice: propertyData.total_price + selectedAddonsSummary.totalCost
      }

      console.debug('payload', payload);

      fetch(`${API_BASE_URL}/api/utils/send-mail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY
        },
        body: JSON.stringify(payload),
        credentials: 'include'
      })
        .then(response => response.json())
        .then(data => {
          // FIXME: Replace with a proper alert or notification
          // alert('Tack för din bokningsförfrågan. Vi kommer återkomma till dig så snart som möjligt med en bekräftelse!')
          setFormData(defaultFormData);
          setSelectedAddons({});
          setShowSuccessModal(true);
        })

    } else {
      console.debug('not valid form')
    }
  }, [
    validate,
    year,
    startWeek,
    endWeek,
    propertyId,
    propertyData?.name,
    propertyData?.total_price,
    formData.firstName,
    formData.lastName,
    formData.email,
    formData.phone,
    formData.address,
    formData.postalCode,
    formData.city,
    formData.country,
    formData.extraInfo,
    formData.message,
    selectedAddonsSummary
  ]);

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <section className='route booking-form'>
      <div className="booking-form__property">
        <InquiredProperty property={propertyData} />
        <p>
          Observera att detta formulär är en bokningsförfrågan och inte en garanterad bokning. Vi kommer att kontakta dig så snart som möjligt för att bekräfta din bokning.
        </p>

        <RentalAddons
          isLoading={isAddonLoading}
          addonsData={addonsData}
          selectedAddons={selectedAddons}
          handleAddonCheckboxChange={handleAddonCheckboxChange}
          handleAddonDropDownChange={handleAddonDropDownChange}
        />

        <div className="booking-form__total_cost">
          {selectedAddonsSummary.totalCost > 0 && (
            <>
              <h4>Grundläggande kostnad {propertyData.total_price} kr</h4>
              <h4>Tillägg kostnad {selectedAddonsSummary.totalCost} kr</h4>
            </>
          )}
          <h3>Total kostnad {propertyData.total_price + selectedAddonsSummary.totalCost} kr</h3>
        </div>

      </div>

      <div className="booking-form__wrapper">
        <h1>Bokningsformulär</h1>
        <form onSubmit={handleSubmit}>
          <TextField
            name="firstName"
            label="Förnamn"
            value={formData.firstName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.firstName)}
            helperText={errors.firstName}
            size="small"
          />
          <TextField
            name="lastName"
            label="Efternamn"
            value={formData.lastName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.lastName)}
            helperText={errors.lastName}
            size="small"
          />
          <TextField
            name="address"
            label="Adress"
            value={formData.address}
            onChange={handleChange}
            fullWidth
            margin="normal"
            size="small"
          />
          <TextField
            name="email"
            label="Epost"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.email)}
            helperText={errors.email}
            size="small"
          />
          <TextField
            name="phone"
            label="Telefonnummer"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.phone)}
            helperText={errors.phone}
            size="small"
          />
          <TextField
            name="postalCode"
            label="Postnummer"
            value={formData.postalCode}
            onChange={handleChange}
            fullWidth
            margin="normal"
            size="small"
          />
          <TextField
            name="city"
            label="Postadress"
            value={formData.city}
            onChange={handleChange}
            fullWidth
            margin="normal"
            size="small"
          />
          <TextField
            className="booking-form__message"
            name="message"
            label="Övrigt meddelande"
            value={formData.message}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
            margin="normal"
            size="small"
          />

          <FormGroup className="rental-agreement-checkbox">
            <FormControlLabel
              required
              control={
                <Checkbox
                  checked={formData.rentalAgreementConfirmed}
                  name="rentalAgreementConfirmed"
                  onChange={handleCheckboxChange}
                />
              }
            />
            <button type="button" onClick={() => setShowAgreementModal(true)}>Jag har läst och godkänt hyresvillkoren</button>
          </FormGroup>

          <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
            Skicka
          </Button>
        </form>
      </div>

      <ConfirmModal
        isOpen={showAgreementModal}
        closeFn={() => setShowAgreementModal(false)}
        onConfirm={() => {
          setShowAgreementModal(false);
          setFormData(prevState => ({
            ...prevState,
            rentalAgreementConfirmed: true
          }));
        }}
        title="Hyresvillkor"
      >
        <div className="confirm-modal__content">
          {rentalAgreement.map(({ sectionHeader, sectionItems }, headerIndex) => (
            <div key={headerIndex}>
              <h3>{sectionHeader}</h3>
              <ul>
                {sectionItems.map((item, itemIndex) => (
                  <li key={itemIndex}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </ConfirmModal>

      <ConfirmModal
        isOpen={showSuccessModal}
        closeFn={() => setShowSuccessModal(false)}
        title="Bokningsförfrågan skickad"
      >
        <div className="confirm-modal__content">
          <p>Tack för din bokningsförfrågan. Vi kommer att kontakta dig så snart som möjligt för att bekräfta din bokning.</p>
        </div>
      </ConfirmModal>


    </section>
  )
}

export default BookingForm