const apartmentData = [
  {
    id: 1,
    number: 1,
    size: 46,
    beds: 4,
    rooms: 2,
    extraInfo: [
      '2 sängplatser i form av bäddsoffa',
      'Toalett nås genom sovrummet'
    ],
    description: [
      'Denna 46 kvm stora lägenhet med 2 rum och 4 bäddar erbjuder en inbjudande atmosfär och ett ljust vardagsrum. Köket är välutrustat, sovrummet bekvämt för fyra personer, och den privata uteplatsen i en lummig trädgård ger en avkopplande reträtt. Perfekt för vänner eller en liten familj som söker komfort och charm.'
    ],
    descriptionFull: [
      'Välkommen till denna underbara 46 kvadratmeter stora lägenhet som erbjuder en perfekt balans mellan komfort och charm. När du kliver in genom dörren möts du av en varm och inbjudande atmosfär.',
      'Det första som slår dig är det rymliga och ljusa vardagsrummet, där stora fönster släpper in rikligt med naturligt ljus. Här finner du en bekväm soffgrupp, perfekt för avkoppling och samvaro med dina nära och kära. En TV och underhållningssystem är också tillgängliga för mysiga filmkvällar.',
      'Köket är välutrustat med moderna apparater och allt du behöver för att laga en god måltid. Det finns gott om bänkyta och förvaringsutrymme, så att du kan utforska din kulinariska kreativitet.',
      'Det stora sovrummet är omsorgsfullt inrett och inbjuder till god nattssömn. Två bekväma dubbelsängar erbjuder gott om plats för upp till fyra personer. Här kan du vakna upp och njuta av lugnet i din lilla oas.',
      'En av höjdpunkterna med denna lägenhet är den privata uteplatsen som ligger i en lummig trädgård. Den perfekta platsen att njuta av din morgonkaffe eller en kvällsdrink i det friska luften. Trädgården ger en känsla av lugn och avkoppling och ger dig möjlighet att koppla av utomhus.',
      'Denna 2-rumslägenhet på 46 kvm är ett perfekt val för en liten grupp vänner eller en familj som vill uppleva komforten och charmen i en hemtrevlig miljö med tillgång till en härlig privat trädgårdsoas. Här kan du skapa minnen som varar livet ut.',
    ],
    weeklyPrice: 8000,
    image: 'apartment-1-livingroom.jpg',
    images: [
      {
        url: '../images/apartments/1/apartment-1-livingroom.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/1/apartment-1-livingroom-2.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/1/apartment-1-livingroom-3.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/1/apartment-1-bedroom.jpg',
        alt: 'Sovrummet',
      },
      {
        url: '../images/apartments/1/apartment-1-bathroom.jpg',
        alt: 'Badrummet',
      },
      {
        url: '../images/apartments/1/apartment-1-shower.jpg',
        alt: 'Duschen',
      },
      {
        url: '../images/apartments/1/apartment-1-kitchen.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/1/apartment-1-kitchen-2.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/1/apartment-1-garden.jpg',
        alt: 'Egen uteplats',
      }
    ]
  },
  {
    id: 2,
    number: 2,
    size: 30,
    beds: 2,
    rooms: 1,
    description: [
      'Njut av den historiska känslan i en ljus och luftig lägenhet, belägen mitt i hjärtat av Visby med en bedårande havsutsikt. Trots dess 30 m2 erbjuder denna etta en mysig sovalkov för två och ett kompakt, välutrustat kök. Direkt ingång från gatan garanterar en smidig ankomst. Perfekt för de som vill uppleva Visbys charm på nära håll.'
    ],
    descriptionFull: [
      'Välkommen till vår ljusa och luftiga etta, perfekt belägen i Visbys historiska innerstad med en utsikt över havet. Lägenheten har en generös yta på 30 m2, vilket ger gott om plats för att njuta av din vistelse i denna UNESCO-världsarvstad.',
      'Stig direkt in från gatan till ditt hem borta från hemmet. Trots sitt centrala läge, kan du förvänta dig lugn och ro här. Den mysiga sovalkoven är inredd för två personer, vilket gör det till en idealisk plats för par eller vänner på upptäcksfärd i Visby.',
      'För dem som föredrar att tillaga egna måltider under semestern, erbjuder lägenheten ett litet men välutrustat kök med alla nödvändigheter. Oavsett om du vill njuta av en frukost med havsutsikt eller laga en sen middag efter en dag på stan, har du allt du behöver här.'
    ],
    weeklyPrice: 6000,
    image: 'apartment-2-livingroom.jpg',
    images: [
      {
        url: '../images/apartments/2/apartment-2-livingroom.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/2/apartment-2-livingroom-2.jpg',
        alt: 'Vardsgsrummet'
      },
      {
        url: '../images/apartments/2/apartment-2-livingroom-3.jpg',
        alt: 'Vardagsrummet'
      },
      {
        url: '../images/apartments/2/apartment-2-beds.jpg',
        alt: 'Sängplatsen',
      },
      {
        url: '../images/apartments/2/apartment-2-bathroom.jpg',
        alt: 'Badrummet',
      },
      {
        url: '../images/apartments/2/apartment-2-shower.jpg',
        alt: 'Duschen',
      },
      {
        url: '../images/apartments/2/apartment-2-kitchen.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/2/apartment-2-livingroom-table.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/2/apartment-2-livingroom-window.jpg',
        alt: 'Utsikt mot väst'
      }
    ]
  },
  {
    id: 3,
    number: 3,
    size: 15,
    beds: 1,
    rooms: 1,
    description: [
      'Denna kompakta studio på 15 m2 har allt du behöver för en bekväm vistelse, perfekt för solo-resenärer eller ett par. Trots sitt lilla format erbjuder den ett fullt fungerande trinettkök med kylskåp och frysfack, så att du kan laga enkla måltider efter en dag av äventyr i staden. Ett rent och modernt duschrum och WC garanterar din komfort.',
      'Fönstret ger en lugnande utsikt över den pittoreska innergården. Och för dem som önskar lite frisk luft är det bara några steg till den gemensamma uteplatsen - den perfekta platsen för en morgonkaffe eller en sen kvällsdryck under stjärnorna.'
    ],
    weeklyPrice: 4000,
    image: 'apartment-3-livingroom.jpg',
    images: [
      {
        url: '../images/apartments/3/apartment-3-livingroom.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/3/apartment-3-livingroom-2.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/3/apartment-3-livingroom-3.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/3/apartment-3-window.jpg',
        alt: 'Utsikt mot innergården',
      },
      {
        url: '../images/apartments/3/apartment-3-bathroom.jpg',
        alt: 'Badrummet',
      },
      {
        url: '../images/apartments/3/apartment-3-shower.jpg',
        alt: 'Duschen',
      }
    ]
  },
  {
    id: 4,
    number: 4,
    size: 41,
    beds: 4,
    rooms: 2,
    extraInfo: [
      '2 sängplatser i form av bäddsoffa'
    ],
    description: [
      'Förlora dig i tidens charm i denna gamla stallbyggnad, nu ombyggd 2:a, där 1700-talets takbjälkar möter modern design. Rymmer upp till 4 gäster, med ett välutrustat kök med barbänk och bekvämt duschrum. Kliv ut för att njuta av den gemensamma uteplatsen, mitt i Visbys hjärta.'
    ],
    descriptionFull: [
      'Känn historiens vingslag i denna unika 2:a, vars rötter går tillbaka till 1700-talets charmiga stallmiljö. Lägenheten, som kan rymma upp till 4 gäster, kombinerar det rustika med det moderna. De ursprungliga takbjälkarna ger en genuin känsla och vittnar om byggnadens rika historia, samtidigt som de moderna bekvämligheterna garanterar en behaglig vistelse.',
      'Det välutrustade köket, kompletterat med en stilfull barbänk, inbjuder till både enkel frukost och middagslagning. Ett fräscht duschrum och WC bidrar till komforten under din vistelse.',
      'När du önskar en stunds utomhusfrid, kan du enkelt stiga ut till den gemensamma uteplatsen, en perfekt plats att njuta av en bok eller samla tankarna medan du andas in den medeltida atmosfären av Visby.'
    ],
    weeklyPrice: 7000,
    image: 'apartment-4-livingroom.jpg',
    images: [
      {
        url: '../images/apartments/4/apartment-4-livingroom.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/4/apartment-4-livingroom-2.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/4/apartment-4-livingroom-3.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/4/apartment-4-kitchen.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/4/apartment-4-kitchen-2.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/4/apartment-4-kitchen-3.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/4/apartment-4-bedroom.jpg',
        alt: 'Badrummet',
      },
      {
        url: '../images/apartments/4/apartment-4-hallway.jpg',
        alt: 'Hall',
      }
    ]
  },
  {
    id: 5,
    number: 5,
    size: 20,
    rooms: 1,
    beds: 2,
    description: [
      'Njut av en speciell upplevelse i denna 20 m2 etta, belägen på övervåningen med en modern halvplansdesign. Rummet rymmer två personer, komplett med ett fräscht duschrum och WC, i en atmosfär som kombinerar bekvämlighet med historisk charm.'
    ],
    descriptionFull: [
      'Denna unika etta på 20 m2 ligger vackert belägen på övervåningen med en spännande halvplansdesign. Lägenheten har en genomtänkt layout som optimerar varje kvadratmeter, vilket gör det till ett mysigt boende för upp till två personer. Här kan ni avnjuta en bekväm sömn i de två sängarna och förbereda er för dagen i det fräscha duschrummet med WC.',
      'Den högre placeringen ger ett extra inslag av lugn, medan den speciella halvplansdesignen ger en intressant och annorlunda känsla än traditionella lägenheter.',
      'Njut av en unik kombination av funktionell design och historisk atmosfär i Visby'
    ],
    weeklyPrice: 5000,
    image: 'apartment-5-livingroom.jpg',
    images: [
      {
        url: '../images/apartments/5/apartment-5-livingroom.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/5/apartment-5-livingroom-2.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/5/apartment-5-kitchen.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/5/apartment-5-kitchen-2.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/5/apartment-5-bathroom.jpg',
        alt: 'Badrummet',
      },
      {
        url: '../images/apartments/5/apartment-5-bedroom.jpg',
        alt: 'Sovrum',
      }
    ]
  },
  {
    id: 6,
    number: 6,
    size: 27,
    rooms: 1,
    beds: 2,
    description: [
      'Denna strålande etta bjuder in till en unik upplevelse för två, belägen på övervåningen med en intressant halvplansdesign. Varje detalj i lägenheten har tänkts ut för att skapa en atmosfär av både bekvämlighet och charm. Från det fräscha duschrummet med WC till den charmiga köksdelen, allt är utformat för att du ska känna dig hemma. Dessutom, när du känner för lite frisk luft, kan du njuta av den gemensamma uteplatsen – en lugn plats att koppla av och insupa Visbys atmosfär.'
    ],
    weeklyPrice: 6000,
    image: 'apartment-6-livingroom.jpg',
    images: [
      {
        url: '../images/apartments/6/apartment-6-livingroom.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/6/apartment-6-livingroom-2.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/6/apartment-6-livingroom-3.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/6/apartment-6-livingroom-4.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/6/apartment-6-kitchen.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/6/apartment-6-kitchen-2.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/6/apartment-6-bathroom.jpg',
        alt: 'Badrummet',
      },
      {
        url: '../images/apartments/6/apartment-6-shower.jpg',
        alt: 'Dusch',
      }
    ]
  },
  {
    id: 7,
    number: 7,
    size: 28,
    rooms: 1,
    beds: 2,
    description: [
      'Mys upp dig i denna trivsamma 1 rum och kök, perfekt placerad i markplan i Visbys historiska innerstad. Denna välplanerade bostad är idealisk för upp till två gäster och erbjuder alla bekvämligheter för en oförglömlig vistelse. Köket är fullt utrustat för hemlagade måltider, medan det fräscha duschrummet med WC ser till att du börjar dagen rätt.',
      'Njut av utsikten från fönstren som vetter mot den lugna innergården, eller ta en stund att koppla av på den privata uteplatsen i trädgården. Dessutom, om du känner för att mingla eller bara ta det lugnt med en bok, erbjuder den gemensamma uteplatsen i innergården ytterligare möjligheter till avkoppling i en pittoresk miljö.'
    ],
    extraInfo: [
      'Lägenhet kan hyras utanför sommarsäsong. Kontakta oss för mer information.'
    ],
    weeklyPrice: 6000,
    image: 'apartment-7-livingroom.jpg',
    images: [
      {
        url: '../images/apartments/7/apartment-7-livingroom.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/7/apartment-7-livingroom-2.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/7/apartment-7-livingroom-3.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/7/apartment-7-bedroom.jpg',
        alt: 'Sovrum',
      },
      {
        url: '../images/apartments/7/apartment-7-kitchen.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/7/apartment-7-stove.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/7/apartment-7-stove-2.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/7/apartment-7-bathroom.jpg',
        alt: 'Badrummet',
      },
      {
        url: '../images/apartments/7/apartment-7-shower-2.jpg',
        alt: 'Duschen',
      },
      {
        url: '../images/apartments/7/apartment-7-shower.jpg',
        alt: 'Duschen',
      }
    ]
  },
  {
    id: 8,
    number: 8,
    size: 110,
    beds: 3,
    rooms: 3,
    extraBeds: 1,
    description: [
      'Dyk in i lyx och komfort i vår största lägenhet, en rymlig 110 m2 bostad belägen på övervåningen, där du kan njuta av en enastående havsutsikt mot den dramatiska solnedgången. Denna lägenhet erbjuder plats för upp till fyra gäster, med tre bekväma sängar och möjlighet till en extra sängplats.',
      'Här hittar du ett generöst vardagsrum, perfekt för avkoppling eller umgänge med familj och vänner. I anslutning till det moderna och välutrustade köket ligger en charmig liten matsal, som inbjuder till minnesvärda middagar i en intim atmosfär.',
      'Stig ut och andas in Visbys historiska känsla på den gemensamma uteplatsen. Denna lägenhet kombinerar perfekt en känsla av rymd och lyx med den historiska karaktären av Visby, vilket skapar en oförglömlig boendeupplevelse.'
    ],
    weeklyPrice: 11500,
    image: 'apartment-8-livingroom.jpg',
    images: [
      {
        url: '../images/apartments/8/apartment-8-livingroom.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/8/apartment-8-livingroom-2.jpg',
        alt: 'Vardagsrummet',
      },
      {
        url: '../images/apartments/8/apartment-8-dining-room.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/8/apartment-8-view-1.jpg',
        alt: 'Utsikt mot havet',
      },
      {
        url: '../images/apartments/8/apartment-8-kitchen-1.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/8/apartment-8-kitchen-2.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/8/apartment-8-kitchen-3.jpg',
        alt: 'Köket',
      },
      {
        url: '../images/apartments/8/apartment-8-bedroom-1.jpg',
        alt: 'Sovrum',
      },
      {
        url: '../images/apartments/8/apartment-8-bedroom-view-1.jpg',
        alt: 'Sovrum',
      },
      {
        url: '../images/apartments/8/apartment-8-master-bedroom-1.jpg',
        alt: 'Sovrum',
      },
      {
        url: '../images/apartments/8/apartment-8-master-bedroom-2.jpg',
        alt: 'Sovrum',
      },
      {
        url: '../images/apartments/8/apartment-8-master-bedroom-3.jpg',
        alt: 'Sovrum',
      },
      {
        url: '../images/apartments/8/apartment-8-bathroom-2.jpg',
        alt: 'Badrum',
      },
      {
        url: '../images/apartments/8/apartment-8-bathroom-1.jpg',
        alt: 'Badrum',
      },
    ]
  }
]

export {
  apartmentData
}
