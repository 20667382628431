import React from 'react';
import { Checkbox, Select, MenuItem, Typography, Box } from '@mui/material';

import './rental-addons.css'

const RentalAddons = ({
  addonsData,
  isAddonLoading,
  selectedAddons,
  handleAddonCheckboxChange,
  handleAddonDropDownChange,
}) => {
  return (
    <Box className="rental-addons" sx={{ padding: 0.5 }}>
      {isAddonLoading && <Typography variant="body1">Hämtar valbara tillägg</Typography>}

      {addonsData && addonsData.length > 0 && (
        <section className="rental-addons">
          <Typography variant="h5" gutterBottom>
            Tillägg
          </Typography>
          {addonsData.map((addon) => (
            <Box key={addon.addon_id} sx={{ marginBottom: 2 }}>
              <label>
                {addon.max_units === 1 ? (
                  <>
                    <Checkbox
                      checked={!!selectedAddons[addon.addon_id]}
                      onChange={() => handleAddonCheckboxChange(addon.addon_id)}
                      color="primary"
                      size="small"
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        padding: 0,
                        marginRight: 1,
                        '&:hover': {
                          backgroundColor: '#f0f0f0', // Light gray background on hover
                        },
                      }}
                    />
                    <Typography variant="body1" component="span">
                      {addon.name} ({addon.price_per_unit} kr)
                    </Typography>
                  </>
                ) : (
                  <>
                    <Select
                      value={selectedAddons[addon.addon_id] || 0}
                      onChange={(e) => handleAddonDropDownChange(addon.addon_id, e.target.value)}
                      variant="outlined"
                      displayEmpty
                      size="small"
                      sx={{
                        backgroundColor: 'white',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'rgba(0, 0, 0, 0.23)', // Default border color
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#000', // Darker border on hover
                        },
                      }}
                    >
                      <MenuItem value={0}>0</MenuItem>
                      {[...Array(addon.max_units).keys()].map((num) => (
                        <MenuItem key={num + 1} value={num + 1}>
                          {num + 1}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="body1" component="span" sx={{ marginLeft: 2 }}>
                      {addon.name} ({addon.price_per_unit} kr/st)
                    </Typography>
                  </>
                )}
              </label>
            </Box>
          ))}
        </section>
      )}
    </Box>
  );
};

export default RentalAddons;
