import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

import './confirm-modal.css';

const ConfirmDialog = ({
  isOpen,
  closeFn,
  title,
  children,
  onConfirm
}) => {
  const handleClose = () => {
    closeFn();
  };

  const handleConfirm = () => {
    onConfirm && onConfirm();
    closeFn();
  }

  return (
    <Dialog
      className="confirm-modal"
      sx={{ '& .MuiDialog-paper': { width: '90%', maxHeight: '90%' } }}
      maxWidth="xs"
      // TransitionProps={{ onEntering: handleEntering }}
      open={isOpen}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
      <DialogActions>
        <Button className="close-button" onClick={handleClose}>Stäng</Button>
        {onConfirm && <Button onClick={handleConfirm}>Jag bekräftar</Button>}
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
